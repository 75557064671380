import { joinURL } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'
import { createOperationsGenerator } from '#image'

const operationsGenerator = createOperationsGenerator()

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL = 'https://media.gokonfetti.com/', ...rest } = {},
  ctx
) => {
  // media.gokonfetti.com/4334/300x0/event-4334.webp
  // media.gokonfetti.com/4334/300x0/b691214e6bb6c295b14d7e13aa3ad31c.webp

  const validWidths = Object.values(ctx.options.screens || {}).sort(
    (a, b) => a - b
  )
  const largestWidth = validWidths[validWidths.length - 1]
  let width = Number(modifiers?.width || 0)

  if (!width) {
    width = validWidths.find((validWidth) => validWidth > width) || largestWidth
  }
  const prefix = '/event-'
  const operations = operationsGenerator(modifiers)
  // console.log('nimg src', src.replace('/', ''), {
  //   src,
  //   modifiers,
  //   rest,
  //   ctx,
  //   width,
  //   operations,
  // })
  return {
    url: joinURL(
      baseURL,
      src,
      `${width}x0`,
      prefix + src.replace('/', '') + '.webp'
    ),
  }
}
